<template>
  <div class="backstage">
    <h5 class="backstage_title">Бэкстейджи с фотосессий</h5>
    <ul class="backstage_list d-flex flex-column flex-sm-row flex-wrap justify-content-around align-items-center">
        <li
          v-for="(image, index) in media"
          :key="index"
          class="backstage_list__item mb-3"
          style="display: inline-block"
          @click="openGallery(index)"
        >
          <img src="../assets/play-button.png" alt="" class="play_sign">
          <img
            v-lazy="image.src || image.thumb"
            class="backstage_list__item__image"
            style="height: 300px"
            
          >
        </li>
     </ul>
    <LightBox 
      :media="media" 
      :showLightBox="false"
      ref="lightbox"
    ></LightBox>
  </div>
</template>

<script>
  import LightBox from 'vue-image-lightbox';
  require('vue-image-lightbox/dist/vue-image-lightbox.min.css')

export default {
  name: 'Backstage',
  metaInfo: {
    title: 'Фотограф Краснодар',
    meta: [
      { vmid: 'description_bs', property: 'description',  name: 'description', content: 'красивая фотосессия в Краснодаре' },
    ],
  }, 
  components: { LightBox },
    data() {
      return {
        media: [
          { 
            thumb: require('@/assets/images/bstgthumbs/bt1.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg1.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt2.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg2.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt3.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg3.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt4.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg4.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt5.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg5.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt6.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg6.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt7.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg7.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt8.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg8.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
          { 
            thumb: require('@/assets/images/bstgthumbs/bt9.png'),
            sources: [
              {
                src: require('@/assets/videos/backstage/bstg9.mp4'),
                type: 'video/mp4'
              },
            ],
            type: "video",
            caption: '<h4></h4>',
            width: 800, 
            height: 600, 
            autoplay: true, 
          },
        ]
      }
    },
    methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style lang="scss">
  .backstage{
    &_list{
      &__item{
        position: relative;
        cursor: pointer;
        .play_sign{
          height: 40px;
          position: absolute;
          top: 45%;
          left: 45%;
          opacity: 0.85;
        }
        &__image{}
      }
    }
  }
</style>
